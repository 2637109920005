<template>
  <div class="flex grow justify-center px-4">
    <template v-if="step === 'contact'">
      <slot />
    </template>
    <template v-else-if="step === 'code'">
      <div
        class="flex w-full max-w-lg flex-col items-center space-y-7"
        :class="!showInDrawer ? 'justify-center' : ''"
      >
        <a
          v-if="!showInDrawer"
          class="text-grays-darkest cursor-pointer self-start"
          @click="step = 'contact'"
          >&larr; Back</a
        >
        <h1 class="text-grays-darkest text-2xl font-semibold">
          Enter verification code
        </h1>
        <p class="text-grays-darkest text-center">
          Please enter the 4-digit code sent to
          <span class="font-semibold">{{
            formattedContact?.formatted || contact
          }}</span>
          {{
            isWelcomePage
              ? 'to verify your account'
              : 'to complete your checkout'
          }}
        </p>
        <div :class="showInDrawer ? 'space-x-2' : 'space-x-4'">
          <input
            v-for="(_digit, index) in codeDigits"
            :key="index"
            :ref="(el) => setInputRef(el as HTMLInputElement, index)"
            v-model="codeDigits[index]"
            pattern="[0-9]*"
            type="text"
            inputmode="numeric"
            maxlength="1"
            autocomplete="one-time-code"
            class="bg-grays-lighter focus:border-grays-dark border-grays-lighter disabled:text-grays-darkest text-grays-darkest h-20 w-20 rounded-lg text-center text-3xl focus:border-2 focus:outline-none focus:ring-0"
            :class="{
              'focus:border-red-medium border-2 border-red-500': codeError,
            }"
            :disabled="!codeError && codeDigits[index] !== ''"
            @paste="handlePaste($event)"
            @input="handleInput(index, $event)"
            @focus="handleClearError()"
            @keydown.backspace="handleBackspace(index)"
          />
        </div>
        <span v-if="codeError" class="text-red-medium"
          >The code entered is incorrect. Please check your code and try again.
        </span>
        <ZnButton
          :disabled="code.length !== 4"
          :class="`w-full text-center ${
            code.length !== 4 ? 'bg-grays-light text-white' : ''
          }`"
          @click="handleVerify()"
        >
          <span v-if="!(loading || forceLoading)">Verify code</span>
          <ZenniIconsIconLoading v-else class="text-white" />
        </ZnButton>
        <div class="h-12">
          <span class="text-grays-darkest">
            Didn't get a code?
            <span v-if="timeout !== null" class="text-grays-dark">
              Resend in :{{ timeout }}
            </span>
            <span
              v-else
              class="text-grays-darkest cursor-pointer underline underline-offset-2"
              @click="handleSendCode()"
              >Resend</span
            >
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useOtp } from '@/composables/api/useOtp'
import { toast } from '@/components/Toast'
import {
  ref,
  toRefs,
  computed,
  watch,
  onMounted,
  onUnmounted,
  nextTick,
  useRoute,
} from '#imports'

const emit = defineEmits<{
  (event: 'verified', token: string): void
}>()

const props = defineProps({
  contact: {
    type: String,
    required: false,
    default: () => '',
  },
  isPhone: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  forceLoading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  showInDrawer: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  storeId: {
    type: String,
    required: false,
    default: undefined,
  },
})

const { forceLoading } = toRefs(props)
const timeoutDuration = 60
const contact = ref<string>(props.contact)
const codeError = ref<boolean>(false)
const timeout = ref<number | null>(null)
const timer = ref<ReturnType<typeof setInterval> | null>(null)
const step = ref<'contact' | 'code'>('contact')
const loading = ref<boolean>(false)

watch(
  () => props.contact,
  () => {
    contact.value = props.contact
  },
)
const isWelcomePage = computed(() => {
  return useRoute().path === '/welcome'
})

const formattedContact = computed<{ formatted: string; e164: string } | null>(
  () => {
    if (props.isPhone) {
      const parsedPhone = parsePhoneNumberFromString(contact.value, 'US')

      if (parsedPhone) {
        const formatted = parsedPhone.formatNational()
        const e164 = parsedPhone.format('E.164')

        return {
          formatted,
          e164,
        }
      }
    }

    return null
  },
)

const clearTimer = () => {
  if (timer.value !== null) {
    clearInterval(timer.value)
    timer.value = null
  }
}

const startTimer = () => {
  clearTimer()
  timer.value = setInterval(() => {
    if (timeout.value !== null && timeout.value > 1) {
      timeout.value -= 1
    } else {
      timeout.value = null
      clearTimer()
    }
  }, 1000)
}

watch(
  () => step.value,
  () => {
    if (step.value === 'code') {
      nextTick(() => {
        const firstInput = codeInputs.value[0]
        firstInput.focus()
      })
    }
  },
)

const codeDigits = ref<string[]>(['', '', '', ''])
const codeInputs = ref<HTMLInputElement[]>([])

const setInputRef = (el: HTMLInputElement | null, index: number) => {
  if (el) {
    codeInputs.value[index] = el as any
  }
}
const code = computed<string>(() => codeDigits.value.join(''))

const handleInput = (index: number, event: Event) => {
  const input = event.target as HTMLInputElement
  codeDigits.value[index] = input.value
  if (index < 3 && input.value !== '') {
    focusNext(index)
  } else if (index > 0 && input.value === '') {
    focusPrevious(index)
  }
}

const focusNext = (index: number) => {
  if (index < 3) {
    const nextInput = codeInputs.value[index + 1]
    nextInput.focus()
  }
}

const focusPrevious = (index: number) => {
  if (index > 0) {
    const prevInput = codeInputs.value[index - 1] as HTMLInputElement
    prevInput.focus()
  }
}

const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault()
  const paste = event.clipboardData?.getData('text') || ''
  const digits = paste.replace(/\D/g, '').slice(0, 4).split('')

  digits.forEach((digit, index) => {
    codeDigits.value[index] = digit
  })

  focusNext(2)
}

const handleClearError = () => {
  if (codeError.value) {
    codeDigits.value = ['', '', '', '']
    codeError.value = false
    codeInputs.value[0].focus()
  }
}

const handleBackspace = async (index: number) => {
  if (index > 0) {
    codeDigits.value[index - 1] = ''
    await nextTick(() => focusPrevious(index))
  }
}

const { start, verify } = useOtp()

const handleSendCode = async () => {
  if (loading.value) {
    return
  }
  try {
    reset()
    handleClearError()
    loading.value = true

    const { error } = await start(
      props.isPhone
        ? {
            userPhoneNumber: formattedContact.value?.e164,
            storeId: props.storeId,
          }
        : { userEmail: contact.value, storeId: props.storeId },
    )

    if (error.value) {
      toast.open({
        type: 'error',
        title: 'Error occured',
        content:
          error.value.statusCode === 404 && props.isPhone
            ? "Couldn't find your account. Try using your email address instead."
            : 'Could not find your user account. If the issue persists, contact support.',
      })
    } else {
      step.value = 'code'
      timeout.value = timeoutDuration
      startTimer()
    }
  } finally {
    loading.value = false
  }
}

const handleVerify = async () => {
  if (loading.value) {
    return
  }
  try {
    loading.value = true

    if (code.value?.length === 4) {
      const { data, error } = await verify({
        code: code.value,
        ...(props.isPhone
          ? { userPhoneNumber: formattedContact.value?.e164 }
          : { userEmail: contact.value }),
        storeId: props.storeId,
      })

      if (data.value) {
        emit('verified', data.value.token)
      } else if (error.value) {
        codeError.value = true
      }
    }
  } finally {
    loading.value = false
  }
}

watch(
  () => code.value,
  () => {
    if (code.value?.length === 4) {
      handleVerify()
    }
  },
)

const reset = () => {
  step.value = 'contact'
  codeDigits.value = ['', '', '', '']
  codeError.value = false
  clearTimer()
}

onMounted(() => codeInputs.value[0]?.focus())

onUnmounted(() => {
  reset()
})

defineExpose({ contact, loading, handleSendCode, reset })
</script>

<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
